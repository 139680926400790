import Cookie from 'js-cookie'
import { v4 as UUID } from 'uuid'
import { ct_sid_name, ct_vid_name, ct_loggedIn_name, ct_hideWelComeBack_name } from '../static/models/cookies.names'

export const getUUId = () => `${UUID()}${UUID()}${UUID()}${UUID()}`

export const getOneHourTime = () => {
  const now = new Date()
  let time = now.getTime()
  time += 1000 * 60 * 60 // One Hour
  now.setTime(time)
  return now
}

export const setNewCtSidCookie = () => {
  Cookie.set(ct_sid_name, getUUId(), { expires: getOneHourTime(), domain: process.env.CT_DOMAIN, })
}

export const updateCtSidCookie = () => {
  const oldValue = Cookie.get(ct_sid_name)
  Cookie.set(ct_sid_name, oldValue, { expires: getOneHourTime(), domain: process.env.CT_DOMAIN, })
}

export const setNewCtVidCookie = () => {
  Cookie.set(ct_vid_name, getUUId(), { expires: 720, domain: process.env.CT_DOMAIN, })
}

export const updateCtVidCookie = () => {
  const oldValue = Cookie.get(ct_vid_name)
  Cookie.set(ct_vid_name, oldValue, { expires: 720, domain: process.env.CT_DOMAIN, })
}

export const setLoginCookie = () => {
  const expirationDate = new Date()
  expirationDate.setMonth(expirationDate.getMonth() + 3)
  Cookie.set('ct_loggedIn', 'true', {
    domain: process.env.CT_DOMAIN,
    path: '/',
    expires: expirationDate,
  })
}
export const checkLoggedInCookie = (): boolean => {
  return Cookie.get(ct_loggedIn_name) === 'true'
}

export const setReferralCodeCookie = referral_code => {
  const in7Days = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7)
  Cookie.set('referral_code', referral_code, { expires: in7Days, domain: process.env.CT_DOMAIN, })
}
export const checkHideWelcomeBackCookie = (): boolean => {
  return Cookie.get(ct_hideWelComeBack_name) === 'true'
}
export const setHideWelcomeBackCookie = () => {
  const in7Days = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7)
  Cookie.set(ct_hideWelComeBack_name, 'true', { expires: in7Days, domain: process.env.CT_DOMAIN, })
}

export const getLanguageCookie = (): string => Cookie.get(process.env.CT_LANGUAGE_COOKIE)

export const getCurrencyCookie = (): string => Cookie.get(process.env.CT_CURRENCY_COOKIE)

export const setEmailCookie = (email: string) => {
  Cookie.set('email', email, { expires: 720, domain: process.env.CT_DOMAIN, })
}

