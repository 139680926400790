/* eslint-disable max-len */
import React from 'react'
import { useTranslation } from 'next-i18next'
import styles from './DiscountBanner.module.scss'

const DiscountBanner: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.banner_dis}>
      <div className={styles.slider_banner}>
        <p className={styles.text_banner}>
          <span className={styles.highlight}>
            {t('discount.available').split('!')[0]}!
          </span>
          {t('discount.available').split('!')[1]}
        </p>
      </div>
    </div>
  )
}

export default DiscountBanner
