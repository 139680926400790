/* eslint-disable no-console */
import * as api from './api'
import Cookie from 'js-cookie'
import {
  setNewCtSidCookie,
  updateCtSidCookie,
  setNewCtVidCookie,
  updateCtVidCookie,
} from 'src/helpers/cookies.helpers'
import { ct_sid_name, ct_vid_name } from 'src/static/models/cookies.names'
import { AppDispatch } from 'src/app/store'

export const checkCookies = () => async () => {
  const ct_sid_cookie = Cookie.get(ct_sid_name)
  const ct_vid_cookie = Cookie.get(ct_vid_name)

  // Check CT Session ID Cookie
  if (!ct_sid_cookie) setNewCtSidCookie()
  else updateCtSidCookie()

  // Check CT Visitor ID Cookie
  if (!ct_vid_cookie) setNewCtVidCookie()
  else updateCtVidCookie()

  // Create Visitor
  await api.postVisitorAPI({ ct_vid: Cookie.get(ct_vid_name) })
}

export const trackEvent = (
  url: string,
  event: string,
  eventValue?: string,
  eventData?: string
) => async () => {
  try {
    await api.postTrackingEventAPI(url, event, eventValue, eventData)
  } catch (error) {
    console.error(`Error on Tracking event`, error)
  }
}

export const handleTrackingSearch = () => (dispatch: AppDispatch) => {
  const trackingSearch = localStorage.getItem('trakingSearch')

  if (trackingSearch) {
    dispatch(checkCookies())
    dispatch(
      trackEvent(
        'MARKET_HOME',
        'ACTION_CLICK',
        'HEADER_SEARCH_BOX',
        trackingSearch
      )
    )
    localStorage.removeItem('trakingSearch')
  }
}
